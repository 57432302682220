.search {
  text-align: center;
}

/* Search boxes */

.tf-custom .tf-nc .node-text {
  padding: 0.4em;
  border-radius: 3px;
  cursor: pointer;
  display: block;
  min-height: 30px;
  min-width: 30px;
  color: white;
  text-align: center;
  font-size: 15px;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}

.tf-custom .tf-nc .node-text:empty:before {
  content: "...";
}

.tf-custom .tf-nc .s {
  border: 1px dotted silver;
  border-radius: 3px;
  font-size: 10.5px;
  cursor: pointer;
  display: block;
  margin: 7px auto 0;
  width: 23px;
}

.tf-custom .tf-b {
  cursor: pointer;
}

.add-node {
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
}

.st {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
}

.sr {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}

.sl {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(0, -50%);
}

.ar {
  position: absolute;
  bottom: 0%;
  right: 0%;
}

.al {
  position: absolute;
  bottom: 0%;
  left: 0%;
}

.del-node {
  position: absolute;
  top: 0;
  right: 0;
}

.ta {
  position: absolute;
  top: 0;
  left: 0;
}

#tf-tree li:only-child > .tf-nc span.sr,
#tf-tree li:only-child > .tf-nc span.sl {
  display: none;
}

#tf-tree li:first-child > .tf-nc span.sl {
  display: none;
}

#tf-tree li:last-child > .tf-nc span.sr {
  display: none;
}

#tf-tree > ul > li > .tf-nc > span.del-node,
#tf-tree > ul > li > .tf-nc > span.st {
  display: none !important;
}

.tf-b {
  display: none;
}

#tf-tree .tf-nc:hover > .tf-b {
  display: block;
}

li span.target {
  background: aliceblue;
}

.tf-custom .tf-nc {
  background-color: dodgerblue;
  background: linear-gradient(-45deg, #1873cc 0%, dodgerblue 100%);
  padding: 1.5em;
  border: none;
  border-radius: 5px;
}

.tf-custom .tf-nc:before,
.tf-custom .tf-nc:after {
  border-left-color: dodgerblue;
  border-left-width: 2px;
}

.tf-custom li li:before {
  border-top-color: dodgerblue;
  border-top-width: 2px;
}

/* Tree visualization of results */

.tf-result {
  font-size: 90%;
  line-height: 1.33em;
}

.tf-result .tf-nc {
  text-align: center;
  /*background: linear-gradient(-45deg, #ffc999, peachpuff);*/
  border: none;
  border-radius: 5px;
  padding: 0 1px 0px;
}

.tf-result .tf-nc.active {
  font-weight: 400;
  background: linear-gradient(-45deg, #e6c300, gold);
  padding: 2px 6px;
}

.tf-result .tf-nc:before,
.tf-result .tf-nc:after {
  border-left-color: slategrey;
  border-left-width: 2px;
}

.tf-result li li:before {
  border-top-color: slategrey;
  border-top-width: 2px;
}

.tf-result.tf-tree li {
  padding: 0 0.14em;
}

.tf-result .tf-nc .node-text {
  font-family: zephtext;
}

.tf-result .res-relation {
  color: red;
  font-size: 80%;
}

/* Slider */

#slider {
  height: 10px;
  width: 35%;
  margin: 10px auto;
}

#slider .noUi-connect {
  background: linear-gradient(-45deg, silver 0%, lightgray 100%);
}

#slider .noUi-handle {
  height: 14px;
  width: 14px;
  top: -3px;
  right: -7px; /* half the width */
  border-radius: 9px;
  background: linear-gradient(-45deg, #961d1d, firebrick);
  border: none;
}

#slider,
#slider .noUi-handle {
  box-shadow: none;
}

#slider .noUi-handle::before,
#slider .noUi-handle::after {
  display: none;
}

#slider .noUi-touch-area {
  cursor: pointer;
}

.slider-input.disabled {
  background: gainsboro;
  color: grey;
}
